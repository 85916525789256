
  .table-post-settlements  th.ant-table-selection-column 
  .ant-table-header-column 
  .ant-table-column-title 
  .ant-table-selection 
  .ant-checkbox-wrapper{
    display:none ;
  }

  .history-item-title{
    font-weight: bold;
    color: #113C71;
    padding-bottom: 10px;
  }
  
  .history-item-first-column{
    font-weight: bold; 
    color: #113C71;
  }

  .history-item-next-columns{
    font-weight: bold;
    color: #113C71;
    padding-left: 70px;
  }

  .modal-light-blue-background {
    background-color: #F1F7FF;
    margin-left: -24px;
    margin-right: -24px;
  }

  .row-blue-letters {
    padding: 10px 0px;
    background-color: #FFFFFF;
    text-align: center;
    color: #2E78CE;
  }

  .white-row {
    padding: 10px 0px;
    background-color: #FFFFFF;
    text-align: center;
  }

  .table-header {
    padding: 10px 24px;
    background-color: #f0f2f5;
    text-align: center;
    font-weight: bold;
    margin: 0px -24px;
  }
  
  .collapse-panel-modal {
    margin-top: 12px;
    margin-left: -24px;
    margin-right: -24px;
  }

  .container-name {
    font-weight: bold;
    color: #2E78CE;
  }

  .scroll-container {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 5px;
    height: 120px;
    margin-bottom: 20px;
  }