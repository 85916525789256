
.ant-card.customer-card>.ant-card-head{
    background: #113c71 !important;
    color: white !important;
}
.gutter-box {
    background: #00a0e9;
    padding: 5px 0;
  }

  .customer-form > button{
    margin:.3rem 0;
   }
   
   .customer-card {
       margin: auto;
       max-width: 70rem;
   }
   .align-right-col{
    text-align: right;
}
.ant-form-item-required::before{
    color: #113c71 !important;
    font-weight: bold;
}
.ant-form-item label{
    color:#113c71!important;
    font-weight: bold;
}
.button-customer-serch-style.ant-btn-primary{
    background-color: #e05c20;
    border-color: #e05c20;
    font-weight: bold !important;
}

.ant-btn:hover, .ant-btn:focus {
    color: #e05c20 !important;
    border-color: #e05c20 !important;
    font-weight: normal !important;

}
 .ant-table-thead > tr > th{
    background: #113C71!important;
    color: white;
    font-weight: bold;
}
.custom-table1 .ant-table-thead > tr > th{
    background: #113C71!important;
    color: white;
    font-weight: bold;
}
.custom-table-white .ant-table-thead > tr > th{
    background: #FAFAFA!important;
    color: #5C5C5C;
    font-weight: bold;
}
.custom-table .ant-table-thead > tr > th{
    background: #F9F9F9 !important;
    color: rgb(0, 0, 0);
    font-weight: bold;
}
.ant-table-thead > tr:first-child{
    background: #F9F9F9 ;
    color: rgb(0, 0, 0);
    font-weight: bold;
}
.center-items-card{
    text-align: center;
}

.customer-main-card {
    margin: auto;
}

.ant-card.customer-main-card{
    color: white;
    max-width: 500rem;
    height: 49rem;
}
.bad-customer-checkbox{
    position:relative;
    left:1.6rem;
}
.add-customer-address-button{
    float:right;    
    position:relative;
    color: white ;
}
.add-customer-address-button:hover{
    background-color: white;
}
.delete-customer-address-button{
    width: fit-content;
    text-align: left;
}
.delete-customer-address-button:hover,.delete-contact-button:focus{
    background-color: white !important;
}
.add-contact-button:hover,.add-contact-button:focus{
    background-color: white !important;
    color: rgb(0, 202, 0) !important;
    border-color: rgb(0, 202, 0) !important;
}
.text-validation-contact-phones{
    color:red !important;
}
.popover-buttons{
    text-align: right;
    margin: 5px;
}
.grey-table-row{
    background-color: #f0f2f5;
}
.btn-save-udr {
    background-color: #e05c20;
    border-color: #e05c20;
    font-weight: bold !important;
    color: #FFFFFF;
    margin-top: 10px;
}