.red-row-table {   
    background-color: #FFF4F4;
}

.red-row-list {   
    background-color: #FFF4F4;
    padding: 10px;
    margin: -7px;
}

.white-row-list {
    background: white;
    padding: 10px;
    margin: -7px;
}