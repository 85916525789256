/* Styles for CreateDepositStatement */
.white-text-table {
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
}

.blue-text-table {
    font-size: 16px;
    color: #2E78CE;
    text-align: center;
}

.grey-text {
    font-size: 16px;
    color: #646464;
    text-align: center;
}

.base-for-table {
    width: 386px;
    height: 120px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
}

.blue-cell-table-title {
    border: 1px solid #527FBA;
    background: #527FBA;
    text-align: center;
    height: 40px;
    font-weight: bold;
    padding: 1%;
}

.white-cell-table-title {
    border: 1px solid #DDDDDD;
    text-align: center;
    height: 40px;
    font-weight: bold;
    padding: 1%;
}

.cell-table-body {
    border: 1px solid #DDDDDD;
    text-align: center;
    height: 40px;
    padding: 1%;
}

.grey-cell-table-body {
    border: 1px solid #DDDDDD;
    background-color: #FAFAFA;
    text-align: center;
    height: 40px;
    padding: 1%;
}

.header-bar {
    height: 107px;
    background-color: #FAFAFA;
    padding:20px;
    margin-left: -24px;
    margin-top: -24px;
    margin-right: -24px;
    margin-bottom: 10px;
    border-bottom: 1px solid #DDDDDD;
}

.info-message {
    width: 800px;
    height: 500px;
    background: #FFFFFF;
    text-align: center;
}

.left-column {
    padding-right: 1.5%;
}

.collapse-container {
    width: 386px;
    height: 81px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
}

.collapse-title {
    padding-bottom: 8px;
    background-color: #F1F7FF;
    border-color: #DDDDDD;
}

.row-collapse-content {
    height: 40px;
    background-color: #FFFFFF;
    font-size: 15px;
    color: #113C71;
    border-bottom: 1px solid #E5E5E5;
    text-indent: .2Rem;
}

.col-collapse-content {
    height: 40px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #E5E5E5;
    text-indent: .2Rem;
}

.money-col-collapse-content {
    text-align: right;
}

/* Styles for depositTableToApply */
.blue-text {
    font-size: 16px;
    color: #2E78CE;
    text-align: left;
}

.margined-table {
    border: 1px solid #DDDDDD;
    margin: 20px 0px;
}

/* Styles for validatorDepositModal */
.base-for-table-short {
    width: 386px;
    height: 82px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
}
.table-deposit-settlements  th.ant-table-selection-column 
.ant-table-header-column 
.ant-table-column-title 
.ant-table-selection
.ant-checkbox-wrapper{
  display:none ;
}
.table-deposit-settlements .ant-table-thead > tr > th{
    background: #FAFAFA!important;
    color: #5C5C5C;
    font-weight: bold;
}

.clarifications-scroll-container {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 16px;
    height: 350px;
    margin: -24px;
  }


ul.ant-comment-actions{
    margin: 0 !important;
}
span.commentAction:hover{
    color: royalblue !important;
}